'use client'

import {createContext} from 'react'
import {useState} from 'react'

const PageContext = createContext()

export const PageContextProvider = ({children}) => {
  const [contextPage, setContextPage] = useState({})

  return <PageContext.Provider value={{contextPage, setContextPage}}>{children}</PageContext.Provider>
}

export default PageContext

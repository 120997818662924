'use client'
import posthog from 'posthog-js'
import {PostHogProvider} from 'posthog-js/react'
import {usePathname, useSearchParams} from 'next/navigation'
import {useContext, useEffect, ReactNode} from 'react'
import {ProfileContext} from '@/contexts/profile'

if (
  typeof window !== 'undefined' &&
  process.env['NEXT_PUBLIC_POSTHOG_KEY'] &&
  process.env['NEXT_PUBLIC_POSTHOG_HOST']
) {
  posthog.init(process.env['NEXT_PUBLIC_POSTHOG_KEY'], {
    api_host: process.env['NEXT_PUBLIC_POSTHOG_HOST'],
    debug: false,
  })
}

export default function PosthogProvider({
  children,
  profileId,
}: {
  children: ReactNode
  profileId: string | number
}): JSX.Element {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  if (profileId) {
    posthog.identify(profileId.toString())
  }

  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname
      if (searchParams.toString()) {
        url = `${url}?${searchParams.toString()}`
      }
      posthog.capture('$pageview', {
        $current_url: url,
      })
    }
  }, [pathname, searchParams])

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

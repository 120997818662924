import PosthogProvider from './posthog-provider'

const PosthogWrapper: React.FC<{children: React.ReactNode; profileId?: string | number}> = ({children, profileId}) => {
  if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    return <PosthogProvider profileId={profileId}>{children}</PosthogProvider>
  } else {
    return <>{children}</>
  }
}

export default PosthogWrapper
